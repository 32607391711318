<template>
  <div class="py-16 about">
    <div class="mb-10 lg:max-w-5xl md:mb-12">
      <h2
        class="mb-6 text-5xl font-bold leading-none tracking-tight text-purple"
      >
        About
      </h2>
      <h2
        class="mb-6 text-3xl font-bold leading-none tracking-tight text-gray-900 "
      >
        World class infrastructure team, long-term Solana experience.
      </h2>
      <p class="text-base text-gray-700 md:text-lg">
        The team behind Triton has decades of experience in running critical
        infrastructure and high performance services. We have got staff and
        partners across the world available to support your needs at any time of
        the day.
      </p>
    </div>

    <div className="grid md:grid-cols-3 gap-8">
      <div>
        <h3 className="font-semibold text-lg">Brian Long</h3>
        <p>
          An experienced Solana validator and entrepreneur located in Boulder,
          CO. Runs
          <a
            href="https://www.validators.app"
            target="_blank"
            rel="noopener noreferrer"
            className="text-purple font-semibold"
            >Validators.app</a
          >
          and
          <a
            href="https://www.blocklogic.net"
            target="_blank"
            rel="noopener noreferrer"
            className="text-purple font-semibold"
            >Blocklogic</a
          >.
        </p>
      </div>
      <div>
        <h3 className="font-semibold text-lg">Linus Kendall</h3>
        <p>
          Systems administrator and software engineer with over 20 years of
          experience in managing critical services on a variety of platforms.
          Based out of India and UK.
        </p>
      </div>
      <div>
        <h3 className="font-semibold text-lg">Marco Broeken</h3>
        <p>
          Infrastructure architect and administrator with over 20 years
          experience in architecting, building and managing virtualized
          datacenters. Based in the Netherlands. Runs
          <a
            href="https://www.stakeconomy.com"
            className="text-purple font-semibold"
            rel="noopener noreferrer"
            target="_blank"
            >Stakeconomy</a
          >
          and
          <a
            href="https://marinade.finance"
            className="text-purple font-semibold"
            rel="noopener noreferrer"
            target="_blank"
            >Marinade</a
          >.
        </p>
      </div>
      <div>
        <h3 className="font-semibold text-lg">Daniel Camarago</h3>
        <p>
          Systems administrator with 5 years of experience, a young but
          determined Jedi. Based in Lisbon, Portugal.
        </p>
      </div>
      <div>
        <h3 className="font-semibold text-lg">Nicolás Fernández</h3>
        <p>
          System administrator with more than 7 years of experience, young, but
          playing with the computer since "It's now safe to turn off your
          computer". Based in Buenos Aires, Argentina.
        </p>
      </div>
      <div>
        <h3 className="font-semibold text-lg">Juan Cruz Fernández</h3>
        <p>
          Behind the beard, there is an experienced systems administrator.
          Opensource enthusiast, based in Argentina.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from "@vueuse/head";
export default {
  setup() {
    useHead({
      title: "About: Triton",
      meta: [
        {
          name: "description",
          content: "About Triton's world class infrastructure team.",
        },
      ],
    });
  },
  name: "About",
};
</script>
